import {Link} from 'react-router-dom';
import classNames from 'classnames';
import user from '@/utils/user';

import './index.scss';
import { login } from '../modals';

const Header = ({cur}) => {
    return (
        <header className="header">
            <div className="header-content">
                <div className="logo">
                    <Link to="/"></Link>
                </div>
                <div className="menu">
                    <Link className={classNames(cur === 'home' && 'cur')} to="/">
                        首页
                    </Link>
                    <Link className={classNames(cur === 'findMusic' && 'cur')} to="/find">
                        i听音乐
                    </Link>
                    <Link className={classNames(cur === 'myMusic' && 'cur')} to="/my">
                        我的音乐
                    </Link>
                    <Link className={classNames(cur === 'join' && 'cur')} to="/join">
                        加入我们
                    </Link>
                    <Link className={classNames(cur === 'about' && 'cur')} to="/about">
                        关于
                    </Link>
                </div>
                <div className="user-status">
                    {user.isLogin() ? (
                        <>
                            <Link className="user-info" to="/account">
                                <img src={user.avatar} alt="" />
                                <span>{user.nikeName}</span>
                            </Link>
                            <div className="logout-btn" onClick={()=>{
                                user.logout();
                            }}>登出</div>
                        </>
                    ) : (
                        <div className="login-btn" onClick={()=>{ login.show() }}>登录</div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
